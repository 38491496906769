import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
  TextField,
  Typography
} from '@mui/material';

import { dispatch, useAppSelector } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import LineChart from '../Charts/LineChart';
import ModelSummaryStyled from '../StyledComponents/ModelSummaryStyled';
import { modelSummaryMiddleware, modelSummarySelector } from '../../store/slices/modelSummary';
import { setModelSummaryData, setModelSummaryStatus } from '../../store/slices/modelSummary/middleware';
import {
  setModelSummaryArraysData,
  setModelSummaryArraysStatus
} from '../../store/slices/modelSummaryArrays/middleware';
import { setModelSummarySaveData, setModelSummarySaveStatus } from '../../store/slices/modelSummarySave/middleware';
import RegressionChart from '../Charts/RegressionChart';
import { MbConverter } from '../../helpers/MbConverter';
import { removeLastExtension } from '../../helpers/removeExtension';
import { buttonStyles } from '../../modals/DatasetModal/DatasetModalSteps/Step1/Step1.styles';
import { modelSummaryArraysMiddleware, modelSummaryArraysSelector } from '../../store/slices/modelSummaryArrays';
import { modelSummarySaveMiddleware, modelSummarySaveSelector } from '../../store/slices/modelSummarySave';
import { toast } from 'react-toastify';
import { Simulate } from 'react-dom/test-utils';
import input = Simulate.input;

export interface ModelManagerSummaryDialogProps {
  fileName: any;
  fileSize: number;
  id: number;
}

// eslint-disable-next-line max-lines-per-function
const ModelManagerSummaryDialog = ({ fileName, fileSize, id }: ModelManagerSummaryDialogProps) => {
  const userId = localStorage.getItem('userId');
  const modelSummaryUrl = `/model_summary/get_summary?container_name=containerfromblobservice${userId}&model_name=${fileName.file_name}`;
  const modelSummaryArraysUrl = `model_summary/get_classification_results?model_id=${id}`;
  const modelSummarySaveUrl = 'model_summary/update_model_summary';
  const { modelSummaryStatus, modelSummaryData } = useAppSelector(modelSummarySelector.modelSummaryData);
  const { modelSummaryArraysStatus, modelSummaryArraysData } = useAppSelector(
    modelSummaryArraysSelector.modelSummaryArraysData
  );
  const { modelSummarySaveStatus, modelSummarySaveData } = useAppSelector(
    modelSummarySaveSelector.modelSummarySaveData
  );
  const [inputValue, setInputValue] = useState<any>(
    modelSummaryData?.successfully?.summary?.model_info?.Optimal_threshold ||
      modelSummaryData?.successfully?.summary?.model_info?.threshold
  );
  const [sliderValue, setSliderValue] = useState(inputValue);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [error, setError] = useState('');

  const step = 0.05;
  const min = 0.1;
  const max = 0.9;
  const [confusionMatrix, setConfusionMatrix] = useState({
    TP: 0,
    FP: 0,
    TN: 0,
    FN: 0
  });
  const [metrics, setMetrics] = useState({
    Recall: 0,
    Precision: 0,
    F1_score: 0,
    Accuracy: 0
  });

  const { TP, FP, TN, FN } = confusionMatrix;

  const calculateConfusionMatrix = () => {
    let TP = 0,
      FP = 0,
      TN = 0,
      FN = 0;
    if (Object.keys(modelSummaryArraysData).length > 0) {
      modelSummaryArraysData?.y_proba.forEach((prob: any, index: any) => {
        const predicted = prob >= inputValue ? 1 : 0;
        const actual = modelSummaryArraysData.y_actual[index];

        if (predicted === 1 && actual === 1) TP++;
        if (predicted === 1 && actual === 0) FP++;
        if (predicted === 0 && actual === 0) TN++;
        if (predicted === 0 && actual === 1) FN++;
      });

      setConfusionMatrix({ TP, FP, TN, FN });
    }
  };

  // Generate an array of valid step values
  const validValues: number[] = [];
  for (let value = min; value <= max; value += step) {
    validValues.push(parseFloat(value.toFixed(2)));
  }

  const handleSliderChange = (event: any, newValue: any) => {
    setSliderValue(newValue);
    setInputValue(newValue);
  };

  const handleInputChange = (event: any) => {
    const newValue = event.target.value;

    // Use regex to only allow numbers and decimal points
    const validInput = /^[0-9]*[.,]?[0-9]*$/;

    if (newValue.length > 20) {
      return;
    }

    if (!validInput.test(newValue)) {
      setError('Please enter a valid number');
      return; // Do not proceed if invalid input
    }

    const parsedValue = parseFloat(newValue);

    // Prevent values equal to or greater than 1, or less than or equal to 0
    if (parsedValue >= 1) {
      setError('Value must be less than 1');
    } else if (parsedValue <= 0) {
      setError('Value must be greater than 0');
    } else {
      setError(''); // Clear error for valid values
    }

    setInputValue(newValue);
    setIsDisable(true); // Disable save button during typing
  };

  const handleBlur = (event: any) => {
    let value = parseFloat(event.target.value);

    // Handle empty input separately
    if (event.target.value === '') {
      setError('');
      setIsDisable(true);
      return;
    }

    // Prevent values less than or equal to 0, and greater than or equal to 1
    if (value >= 1) {
      setError('Value must be less than 1');
      setIsDisable(true);
    } else if (value <= 0) {
      setError('Value must be greater than 0');
      setIsDisable(true);
    } else {
      setSliderValue(value);
      setIsDisable(false); // Enable save button for valid values
      setError(''); // No error since the value is valid
    }
  };

  useEffect(() => {
    setSliderValue(inputValue);
  }, [inputValue]);

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.ModelManagerSummaryDialog));
    dispatch(setModelSummaryData({}));
    dispatch(setModelSummaryStatus(0));
    dispatch(setModelSummaryArraysData({}));
    dispatch(setModelSummaryArraysStatus(0));
    dispatch(setModelSummarySaveData({}));
    dispatch(setModelSummarySaveStatus(0));
  };

  const onSaveClick = () => {
    const params = {
      id: id,
      optimal_threshold: inputValue,
      confusion_matrix: confusionMatrix,
      model_performance_metrics: metrics
    };
    dispatch(modelSummarySaveMiddleware.getModelSummarySave(params, modelSummarySaveUrl));
  };

  useEffect(() => {
    if (modelSummaryStatus === 0) {
      dispatch(modelSummaryMiddleware.getModelSummary(modelSummaryUrl));
    }
    if (
      modelSummaryStatus === 200 &&
      modelSummaryData?.successfully?.summary?.model_info?.Problem_type === 'classification'
    ) {
      dispatch(modelSummaryArraysMiddleware.getModelSummaryArrays(modelSummaryArraysUrl));
    }
  }, [modelSummaryStatus]);

  useEffect(() => {
    calculateConfusionMatrix();
  }, [inputValue]);

  useEffect(() => {
    const optimalThreshold =
      modelSummaryData?.successfully?.summary?.model_info?.Optimal_threshold ||
      modelSummaryData?.successfully?.summary?.model_info?.threshold;
    if (optimalThreshold) {
      setInputValue(parseFloat(optimalThreshold));
    }
  }, [
    modelSummaryData?.successfully?.summary?.model_info?.Optimal_threshold,
    modelSummaryData?.successfully?.summary?.model_info?.threshold
  ]);
  useEffect(() => {
    let Recall = 0;
    let Precision = 0;
    let F1_score = 0;
    let Accuracy = 0;

    // Ensure TP, FP, TN, FN are numbers and fall back to 0 if undefined
    const truePositive = TP || 0;
    const falsePositive = FP || 0;
    const trueNegative = TN || 0;
    const falseNegative = FN || 0;

    if (truePositive + falseNegative !== 0) {
      Recall = truePositive / (truePositive + falseNegative);
    }

    if (truePositive + falsePositive !== 0) {
      Precision = truePositive / (truePositive + falsePositive);
    }

    if (Precision + Recall !== 0) {
      F1_score = (2 * Precision * Recall) / (Precision + Recall);
    }

    Accuracy = (truePositive + trueNegative) / (truePositive + trueNegative + falsePositive + falseNegative);

    setMetrics({
      Recall: parseFloat((Recall * 100).toFixed(2)),
      Precision: parseFloat((Precision * 100).toFixed(2)),
      F1_score: parseFloat((F1_score * 100).toFixed(2)),
      Accuracy: parseFloat((Accuracy * 100).toFixed(2))
    });
  }, [TP, FP, TN, FN]);

  useEffect(() => {
    if (modelSummaryArraysData && Object.keys(modelSummaryArraysData).length > 0) {
      calculateConfusionMatrix();
    }
  }, [inputValue, modelSummaryArraysData]);

  useEffect(() => {
    if (modelSummarySaveStatus === 200) {
      setLoading(false); // Stop loading when the save is successful
      toast.success(modelSummarySaveData.message);
      onModalClose(); // Close the modal if status is 200
    } else if (modelSummarySaveStatus !== 0 && modelSummarySaveStatus !== 200) {
      setLoading(false); // Stop loading in case of an error
    }
  }, [modelSummarySaveStatus]);

  useEffect(() => {
    if (
      modelSummaryData?.successfully?.summary?.model_info?.Optimal_threshold === inputValue ||
      modelSummaryData?.successfully?.summary?.model_info?.threshold === inputValue ||
      inputValue === ''
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [inputValue]);

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onModalClose} aria-labelledby="simple-dialog-title" open>
      <ModelSummaryStyled>
        <DialogTitle className="dialog-title" id="draggable-dialog-title">
          Model Summary
          <IconButton onClick={onModalClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogTitle className="dialog-title" id="draggable-dialog-title">
          {`${removeLastExtension(fileName.file_name)} / ${MbConverter(fileSize)}`}
        </DialogTitle>
        {modelSummaryStatus === 200 &&
        (modelSummaryData?.successfully?.summary?.model_info?.Problem_type !== 'classification' ||
          modelSummaryArraysStatus === 200) ? (
          <Card>
            <CardContent sx={{ pt: 0 }}>
              <Grid container spacing={2} columns={12}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    color: '#1E1E1E',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    margin: '5px'
                  }}
                >
                  <Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Dataset -</Typography>
                      <Typography>
                        {removeLastExtension(modelSummaryData.successfully.summary?.model_info.Data)}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Training Date -</Typography>
                      <Typography>{modelSummaryData.successfully.summary?.model_info.Train_date}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Model -</Typography>
                      <Typography>{modelSummaryData.successfully.summary?.model_info.Model}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Problem type -</Typography>
                      <Typography>{modelSummaryData.successfully.summary?.model_info?.Problem_type}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Target variable -</Typography>
                      <Typography>{modelSummaryData.successfully.summary?.model_info?.Target_variable}</Typography>
                    </Box>
                    {modelSummaryData.successfully.summary.model_info.Problem_type === 'classification' && (
                      <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Threshold -</Typography>
                        <Typography>
                          {modelSummaryData.successfully.summary?.model_info?.Optimal_threshold ||
                            modelSummaryData.successfully.summary?.model_info?.threshold}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: 900 }}>Model performance metrics:</Typography>
                    {modelSummaryData.successfully.summary?.model_info.Problem_type === 'regression' && (
                      <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>RMSE =</Typography>
                        <Typography>
                          {parseFloat(modelSummaryData.successfully.summary?.Model_performance_metrics?.RMSE).toFixed(
                            2
                          )}
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info.Problem_type === 'classification'
                          ? 'Recall = '
                          : 'MSE = '}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info.Problem_type === 'classification'
                          ? `${metrics.Recall}%`
                          : `${parseFloat(
                              modelSummaryData.successfully.summary?.Model_performance_metrics?.MSE
                            ).toFixed(2)}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? 'Precision = '
                          : 'MAE = '}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? `${metrics.Precision}%`
                          : `${parseFloat(
                              modelSummaryData.successfully.summary?.Model_performance_metrics?.MAE
                            ).toFixed(2)}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? 'F1-score = '
                          : 'R-Squared = '}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? `${metrics.F1_score}%`
                          : `${parseFloat(modelSummaryData.successfully.summary?.Model_performance_metrics?.R2).toFixed(
                              2
                            )}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? 'Accuracy = '
                          : 'Adjusted R-Squared = '}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? `${metrics.Accuracy}%`
                          : `${parseFloat(
                              modelSummaryData.successfully.summary?.Model_performance_metrics?.Adjusted_R2
                            ).toFixed(2)}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? 'AUC = '
                          : null}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? `${modelSummaryData.successfully.summary?.Model_performance_metrics?.AUC}%`
                          : null}
                      </Typography>
                    </Box>
                    {/*{modelSummaryData.successfully.summary.model_info.Problem_type === 'regression' && <Box sx={{ display: 'flex' }}>*/}
                    {/*  <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>BIC =</Typography>*/}
                    {/*  <Typography>{modelSummaryData.successfully.summary.Model_performance_metrics.BIC}</Typography>*/}
                    {/*</Box>}*/}
                  </Box>
                </Grid>

                {/*my changed code*/}
                {modelSummaryData?.successfully?.summary?.model_info?.Problem_type === 'classification' &&
                  fileName.model_status !== 'Deployed' && (
                    <Grid
                      style={{ maxWidth: '59%', margin: '15px auto' }}
                      item
                      xs={12}
                      container
                      spacing={2}
                      // alignItems="center"
                      sx={{ mt: 2.5 }}
                    >
                      <div style={{ marginBottom: '20px', color: '#000' }}>
                        Adjust the threshold to update the Confusion Matrix and view the changes in Model Performance
                        metrics
                      </div>
                      <Grid item>
                        <Typography variant="h6" color="primary" style={{ marginTop: '15px' }}>
                          0.05
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Slider
                          style={{ marginTop: '10px' }}
                          color="secondary"
                          value={sliderValue}
                          onChange={handleSliderChange}
                          valueLabelDisplay="on"
                          aria-labelledby="discrete-slider-small-steps"
                          marks
                          step={0.05}
                          min={0.05}
                          max={0.95}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" color="primary" style={{ marginTop: '15px' }}>
                          0.95
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Tooltip title={inputValue === NaN ? '' : inputValue} arrow>
                          <TextField
                            type="number"
                            style={{ width: '130%', maxWidth: '140px' }} // Fixed width to prevent size fluctuation
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleBlur} // Validation on blur only
                            error={!!error} // Show error style if there's an error
                            helperText={error} // Show error message if applicable
                            FormHelperTextProps={{
                              style: {
                                visibility: error ? 'visible' : 'hidden',
                                height: '20px',
                                marginLeft: '0',
                                marginRight: '0',
                                whiteSpace: 'nowrap',
                                fontSize: '9px'
                              }
                            }}
                            inputProps={{
                              step: 0.00001, // Allow very fine precision for small values
                              min: 0,
                              max: 1,
                              maxLength: 20
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'e' || event.key === 'E' || event.key === '+' || event.key === '-') {
                                event.preventDefault(); // Prevent invalid characters
                              }
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}

                {/*end of my changed code*/}

                {modelSummaryData.successfully.summary.model_info.Problem_type === 'classification' ? (
                  <Grid container>
                    <Grid item xs={6} style={{ padding: '16px 5px' }}>
                      <Box style={{ backgroundColor: '#EDF2F4' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>
                          ROC-AUC curve
                        </Typography>
                        <LineChart modelSummaryData={modelSummaryData} isSum={true} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '16px 5px'
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#EDF2F4',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'space-evenly'
                        }}
                      >
                        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Confusion Matrix</Typography>
                        <table>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td colSpan={2} style={{ color: '#75b17e', textAlign: 'center' }}>
                              Actual Class
                            </td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style={{ color: '#000000' }}>Positive (1)</td>
                            <td style={{ color: '#000000' }}>Negative (0)</td>
                            <td style={{ color: '#000000' }}>Total</td>
                          </tr>
                          <tr>
                            <td style={{ color: '#e98f8e' }} rowSpan={2}>
                              <p className="rotated-cell">Predicted Class</p>
                            </td>
                            <td style={{ color: '#000000' }}>
                              <p className="rotated-cell">Positive (1)</p>
                            </td>
                            <td style={{ color: '#000000' }} className="false-cell">
                              TP = {confusionMatrix.TP}
                            </td>
                            <td style={{ color: '#000000' }} className="true-cell">
                              FP = {confusionMatrix.FP}
                            </td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {confusionMatrix.TP + confusionMatrix.FP}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: '#000000' }}>
                              <p className="rotated-cell">Negative (0)</p>
                            </td>
                            <td style={{ color: '#000000' }} className="true-cell">
                              FN = {confusionMatrix.FN}
                            </td>
                            <td style={{ color: '#000000' }} className="false-cell">
                              TN = {confusionMatrix.TN}
                            </td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {confusionMatrix.FN + confusionMatrix.TN}
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td style={{ color: '#000000' }}>Total</td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {confusionMatrix.TP + confusionMatrix.FN}
                            </td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {confusionMatrix.FP + confusionMatrix.TN}
                            </td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {confusionMatrix.TN + confusionMatrix.FP + confusionMatrix.FN + confusionMatrix.TP}
                            </td>
                          </tr>
                        </table>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', padding: '16px 5px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          fontSize: '1.25rem',
                          color: '#000000'
                        }}
                      >
                        <p>Actual and Predicted values</p>
                        <RegressionChart data={modelSummaryData} />
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {modelSummaryData?.successfully?.summary?.model_info?.Problem_type === 'classification' &&
                fileName.model_status !== 'Deployed' && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    {/*<div*/}
                    {/*  style={{*/}
                    {/*    color: '#000',*/}
                    {/*    backgroundColor: '#fff2cc',*/}
                    {/*    padding: '10px',*/}
                    {/*    marginLeft: '32%',*/}
                    {/*    fontSize: '16px'*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <span style={{ display: 'block' }}>*/}
                    {/*    Please note that these changes do not affect models that have already been deployed.*/}
                    {/*  </span>*/}
                    {/*  <span style={{ display: 'block' }}>*/}
                    {/*    To apply these changes to deployed models, please undeploy and redeploy the model*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      style={{
                        margin: '20px 0',
                        width: '120px',
                        height: '60px',
                        borderRadius: '8px',
                        fontFamily: 'Roboto',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        backgroundColor: isDisable || error.length > 0 ? '#d9d9d9' : '#334063',
                        color: isDisable || error.length > 0 ? '#635F5F' : '#fff',
                        float: 'right'
                      }}
                      sx={buttonStyles}
                      onClick={onSaveClick}
                      disabled={isDisable || error.length > 0}
                    >
                      {loading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                  </Grid>
                )}
            </CardContent>
          </Card>
        ) : modelSummaryStatus && modelSummaryArraysStatus ? (
          // Handle other status codes here
          <div>
            <Typography variant="h6" color="error">
              Error: Unable to fetch data. Please try again later.
            </Typography>
            <Button onClick={onModalClose} color="primary">
              Close
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '1100px',
              margin: '0',
              height: '100vh'
            }}
          >
            <CircularProgress style={{ color: 'blue' }} />
          </div>
        )}
      </ModelSummaryStyled>
    </Dialog>
  );
};

export default ModelManagerSummaryDialog;
